import { LayoutRouteProps } from "react-router-dom";
import { Footer } from "../common/Footer";
import { Header } from "../common/Header";

export const AppLayout: React.FC<LayoutRouteProps> = ({ children }) => {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>{children}</div>
      <div>
        <Footer />
      </div>
    </div>
  );
};
