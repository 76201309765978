
interface Env {
  appName: string;
  appEnv: string;
  appPort: number;
  appUrl: string;
  dashboardBaseUrl: string;
  apiUserManagementBaseUrl: string;
  linkedInUrl: string;
  bugsnagApiKey: string;
  mixpanelToken: string;
  appDebug : boolean;
  bookDemoLink : string;
}

export const env: Env = {
  appName: process.env.REACT_APP_NAME || "",
  appEnv: process.env.REACT_APP_ENV || "",
  appPort: parseInt(process.env.REACT_APP_PORT || "3000", 10),
  appUrl:
    process.env.REACT_APP_URL || `http://127.0.0.1:${process.env.REACT_APP_PORT || "3000"}`,
  dashboardBaseUrl: process.env.REACT_APP_DASHBOARD_BASE_URL as string || `http://127.0.0.1:3001`,
  apiUserManagementBaseUrl: process.env.REACT_APP_API_USER_MANAGEMENT_BASE_URL || "",
  linkedInUrl: process.env.REACT_APP_LINKEDIN_URL || "",
  bookDemoLink : process.env.REACT_APP_BOOK_DEMO_LINK || "",
  bugsnagApiKey: process.env.REACT_APP_BUGSNAG_API_KEY || "",
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || "",
  appDebug : process.env.REACT_APP_APP_DEBUG === "false" || false,
};
