import React from "react";
import { ScaleLoader } from "react-spinners";

interface LoadingSpinnerProps {
  loading: boolean;
  className? : string
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ loading, className }) => {
  return (
    <div className="loading-spinner">
      <ScaleLoader className={className} color={"rgba(130, 78, 228, 1)"} loading={loading} />
    </div>
  );
};