import React, { useEffect } from "react";
import { env } from "../config/env";
import { checkLoginStatus } from "../redux/actionCreators/authActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../redux/types/AuthTypes";
import { LoadingSpinner } from "./Loader";
interface GuestRouteWrapperProps {
  children: React.ReactNode;
}

const GuestRouteWrapper: React.FC<GuestRouteWrapperProps> = ({
  children,
}): React.ReactElement<any, any> | null => {
  const redirectUri = env.dashboardBaseUrl;

  const dispatch = useDispatch();
  useEffect(() => {
    checkLoginStatus(dispatch);
  }, [dispatch]);

  const authState = useSelector((state: { auth: AuthState }) => state.auth);
  const { isLoggedIn, isLoading } = authState;

  if (isLoading) {
    return (
      <div>
        <LoadingSpinner loading={isLoading} className="loader" />
      </div>
    );
  }

  if (isLoggedIn) {
    window.location.replace(redirectUri);
    return null;
  }

  return <div>{children}</div>;
};

export default GuestRouteWrapper;
