import {
  CHECK_LOGIN_STATUS_REQUEST,
  CHECK_LOGIN_STATUS_SUCCESS,
  CHECK_LOGIN_STATUS_FAILURE
} from '../actionTypes/authActionTypes';

import { AuthState } from "../types/AuthTypes";

const initialState: AuthState = {
  isLoading: true,
  isLoggedIn: false,
  error: null
};

export const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHECK_LOGIN_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case CHECK_LOGIN_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: action.payload,
        error: null
      };
    case CHECK_LOGIN_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload 
      };
    default:
      return state;
  }
};
