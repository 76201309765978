import { env } from "../config/env";
import FalconAI from "./../assets/Falcon-AI_logo.svg";
import Linkedin from "./../assets/linkedin.svg";
import { Link } from "react-router-dom";

export const Footer: React.FC = () => {
  const links = [
    { title: "Pricing", link: "/pricing" },
    { title: "Privacy Policy", link: "/privacy-policy" },
    { title: "Refund Policy", link: "/refund-policy" },
    { title: "Support", link: "/support" },
    { title: "Terms & Conditions", link: "/termsofservice" },
  ];

  return (
    <div className="footer">
      <div className="footer-parent">
        <div className="footer-container">
          <Link to="/">
            <img className="footer-logo" src={FalconAI} alt="Falcon-AI" />
          </Link>
        </div>
        <div className="footer-container footer-links">
          <ul>
            <h2 className="footer-heading"> Links </h2>
            {links.map((item, index) => {
              return (
                <Link key={index} to={item.link} id="footer-link">
                  <li> {item.title}</li>
                </Link>
              );
            })}
          </ul>
        </div>
        <div className="footer-container">
          <ul>
            <h2 className="footer-heading"> Contact Us: </h2>
            <p id="contact">Vaibhav Kumar Agarwal</p>
            <p id="contact">vaibhav@getfalcon.in</p>
            <p id="contact">36A, 22nd Main Road, Sector 3, HSR Layout,</p>
            <p id="contact">Bengaluru, Karnatka, 560102</p>
          </ul>
        </div>
      </div>
      <div className="footer-container-2">
        <div className="line"></div>
        <div className="footer-last">
          <div className="year">
            © 2024 - Falcon AI Technologies Private Limited
          </div>
          <div className="linkedin">
            <Link to={env.linkedInUrl} target="_blank">
              {" "}
              <img
                className="linkedin-logo"
                src={Linkedin}
                alt="Linkedin-Logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
