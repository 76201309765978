import { Link } from "react-router-dom";
import Falcon from "./../assets/Falcon-AI_logo.svg";
import { ButtonWrapper } from "./buttonWrapper";
import { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa"; // Import the hamburger and close icons

export const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const links = [
    { title: "Features", link: "/" },
    { title: "Integrations", link: "/integrations" },
    { title: "Pricing", link: "/pricing" },
    { title: "Support", link: "/support" },
    // { title: "About", link: "/about" },
  ];

  useEffect(() => {
    const element = document.querySelector(".feature-1");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <nav className="header-box">
      <div className="navbar">
        <div className="logo">
          <Link to="/">
            <img src={Falcon} alt="Falcon-AI" />
          </Link>
        </div>
        <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
          <FaBars />
        </div>
        <div className={`page ${menuOpen ? "open" : ""}`}>
          <div className="close-icon" onClick={() => setMenuOpen(false)}>
            <FaTimes />
          </div>
          {links.map((item, index) => (
            <ul key={index} className="list">
              <Link to={item.link} id="nav_links">
                <li onClick={() => setMenuOpen(false)}>{item.title}</li>
              </Link>
            </ul>
          ))}
          <ul className="button">
            <li>
              <Link to="/waitlist">
                <ButtonWrapper
                  id="getstarted"
                  buttonName="Get Started"
                  service="GET_STARTED"
                  onClick={() => setMenuOpen(false)}
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
