import { Footer } from "../common/Footer";
import { Header } from "../common/Header";

type FeatureCardProps = {
    name: string;
    image: string;
    imageAlt: string;
    available: boolean;
};

const Card: React.FC<FeatureCardProps> = ({
    name,
    image,
    imageAlt,
    available,
  }) => (
    <div className="features-card">
        <div className="features-card-visual">
            <img src={image} alt={imageAlt} className="features-card-image" />
            {available ?
                (
                    <div className="features-card-on-button-body">
                        <div className="features-card-on-button"></div>
                    </div>
                )
                :
                (
                    <div className="features-card-off-button-body">
                        <div className="features-card-off-button"></div>
                    </div>
                )
            }
        </div>
        <div className="features-card-text">
            <h2 className="features-card-title">{name}</h2>
            {available ? 
                (
                    <p className="features-card-description">Available at launch</p>
                )
                :
                (
                    <p className="features-card-description">Coming Soon</p>
                )
            }
        </div>
    </div>
);

const allFeatures: FeatureCardProps[] = [
    {
        name: "Slack",
        image: "https://framerusercontent.com/images/DhUcCcaMNTxd1SOp289MglRPBo.svg",
        imageAlt: "Slack",
        available: true,
    },
    {
        name: "GitHub",
        image: "https://framerusercontent.com/images/bOTDT27npRMwcH3teHaotNt3z14.svg?scale-down-to=1024",
        imageAlt: "GitHub",
        available: true,
    },
    {
        name: "Jira",
        image: "https://framerusercontent.com/images/6Ocrk54JS7Lk7DdVnJL2BzHGyU.svg",
        imageAlt: "Jira",
        available: true,
    },
    {
        name: "Google Meet",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZPmwOQGMkc0tDA32jPfcT1yNghVAYYoGa3LIgRS9LrVoJ_Py3S8Ipw-cCw6I2wFcTbQs&usqp=CAU",
        imageAlt: "Google Meet",
        available: true,
    },
    {
        name: "GitLab",
        image: "https://framerusercontent.com/images/f00g7jcbeslqKQvwpUmMpPZD2wQ.svg",
        imageAlt: "GitLab",
        available: false,
    },
    {
        name: "Trello",
        image: "https://framerusercontent.com/images/V7A2keYvfOyYqYoA2hnD8pfofrE.svg",
        imageAlt: "Trello",
        available: false,
    },
]

export const Integrations: React.FC = () => {
    return (
        <div className="features-parent">
            <Header />
            <h1 className="features-heading">Integrations</h1>
            <div className="features-cards-generator">
                {allFeatures.map((feature, index) => (
                    <Card
                        key={index}
                        name={feature.name}
                        image={feature.image}
                        imageAlt={feature.imageAlt}
                        available={feature.available}
                    />
                ))}
            </div>
            <Footer />
        </div>
    )
};
