import { useEffect, useState } from "react";
import Logo from "./../assets/Falcon-AI_logo.svg";
import vector1 from "./../assets/signup-vector-1.svg";
import vector2 from "./../assets/signup-vector-2.svg";
import vector3 from "./../assets/signup-vector-3.svg";
import Google_vector from "./../assets/google.svg";
import { Link } from "react-router-dom";
import { handleGoogleLogin } from "../services/auth";
import LoadingBar from "react-top-loading-bar";

export enum RouteType {
  Signup = "Sign Up",
  Login = "Log In",
}
interface RouteTypeProp {
  routeType: RouteType;
}
export const SignupOrLogin: React.FC<RouteTypeProp> = ({ routeType }) => {
  const vectorItems = [
    { vector: vector1, title: "Elevate", subtitle: "Your Meetings" },
    { vector: vector2, title: "Empower", subtitle: "Your Team" },
    { vector: vector3, title: "Embrace", subtitle: "Your Efficiency" },
  ];
  const [progress, setProgress] = useState(0);
  const handleClick = () => {
    handleGoogleLogin({ setProgress: setProgress });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <LoadingBar
        color="#605BFF"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="parent-signup">
        <div className="parent-signup-in">
          <div className="signup-left">
            <div className="signup-logo">
              <img className="logo" src={Logo} alt="Logo" />
            </div>
            {vectorItems.map((item, index) => (
              <div className={`signup-left-text`} key={index}>
                <div className="signup-left-vector-container">
                  <img
                    className={`signup-text-vector`}
                    src={item.vector}
                    alt={`Vector`}
                  />
                </div>
                <div className={`text`}>
                  <p className="signup-left-color"> {item.title}</p>
                  <p>{item.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="signup-right">
            <div className="signup-text">
              {routeType === RouteType.Login ? "Log In" : "Sign Up"}
            </div>
            <button className="signup-button" onClick={handleClick}>
              <img className="google-vector" src={Google_vector} alt="Google" />
              <span className="signup-button-text">{`${
                routeType === RouteType.Login ? "Log In" : "Sign Up"
              } with Google`}</span>
            </button>
            <div className="signup-right-text">
              Already have an account?{" "}
              <Link
                id="signup-right-text-colored"
                to={routeType === RouteType.Login ? "/signup" : "/login"}
              >
                <span>
                  {routeType !== RouteType.Login ? "Log In" : "Sign Up"}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
