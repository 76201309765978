import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import Unlock from "../assets/vector-unlock.svg";
import Star from "../assets/Vector.svg";
import Features from "../assets/features.svg";
import Dashboard1 from "../assets/feature_1.svg";
import Dashboard2 from "./../assets/feature-1-vector-2.svg";
import mixpanelService from "./../services/mixpanel";
import { ButtonWrapper } from "../common/buttonWrapper";

export const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    mixpanelService.track("HOW_IT_WORKS");
  }, []);

  document.addEventListener("DOMContentLoaded", () => {
    const playButton = document.getElementById("playButton") as HTMLDivElement;
    const video = document.getElementById("video") as HTMLVideoElement;

    playButton.addEventListener("click", () => {
      if (video.paused) {
        video.play();
        playButton.style.display = "none"; // Hide play button once video starts playing
      }
    });
  });

  const ProductHuntBanner: React.FC = () => {
    return (
      <a
        href="https://www.producthunt.com/posts/falcon-ai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-falcon&#0045;ai"
        target="_blank"
        rel="noopener noreferrer"
        className="product-hunt-banner"
      >
        <img
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=457956&theme=light"
          alt="Falcon&#0032;AI - Your&#0032;AI&#0032;Scrum&#0032;Master | Product Hunt"
          width="350"
          height="100"
        />
      </a>
    );
  };
  return (
    <div>
      {/* Unlock Section */}
      {/* <div className="unlock">
        <img id="unlock-vector" src={Unlock} alt="Unlock" />
      </div> */}

      {/* Middle Component Section */}
      <div className="MiddleComp">
        <div className="unlock-text">
          <p>Your AI Scrum Master</p>
        </div>
        <ProductHuntBanner />
        <div className="introduce-text">
          Saving time for product and engineering managers, and helping projects
          get delivered faster.
        </div>
        <div className="book-demo">
          <Link to="https://calendly.com/mrvaibhav98/connect" target="_blank">
            <ButtonWrapper
              id="BookDemo"
              buttonName="Book a Demo"
              service="BOOK_DEMO"
            />
          </Link>
        </div>
      </div>

      {/* Empty Boxes Section */}
      <div className="main-demo-container">
        <iframe
          title="Demo Video"
          className="video-container"
          src="https://www.youtube.com/embed/0i40r3MM33Y?autoplay=1&mute=1"
        ></iframe>
      </div>

      {/* Fact Section */}
      <div className="fact">
        <div className="fact-vectors">
          <img className="fact-vector-1" src={Star} alt="vector" />
          <img className="fact-vector-2" src={Star} alt="vector" />
        </div>
        <div className="fact-text">
          <div className="fact-1">
            <p>
              Studies have shown that leveraging{" "}
              <span className="fact-color-text">Project Manager</span>
            </p>
            <p>
              can increase meeting efficiency by up to{" "}
              <span className="fact-color-text">40%</span>
            </p>
          </div>
          <div>
            <p>
              Allowing teams to{" "}
              <span className="fact-color-text">
                save valuable time and resources
              </span>{" "}
              while
            </p>
            <p>
              ensuring{" "}
              <span className="fact-color-text">
                clear communication and accountability
              </span>
            </p>
          </div>
        </div>
      </div>

      {/* Feature 1 Section */}
      <div className="feature-1">
        <p id="text-1"> FEATURES </p>
        <p id="text-2"> Let us Handle the details</p>
      </div>

      {/* Feature 1 Container 1 Section */}
      <div className="feature-container">
        <div className="feature-container-text">
          <div className="feature-text-main">
            <p>
              Get <span className="feature-text-colored">Real time</span>{" "}
              updates
            </p>
            <p>and progress on projects</p>
          </div>
          <div className="feature-sub-text">
            <p>Our platform automatically segregates</p>
            <p>real-time updates within dedicated</p>
            <p>channels, keeping your team organized</p>
            <p>and focused.</p>
          </div>
        </div>
        <div className="feature-container-img">
          <img
            className="feature-vector-img"
            src={Dashboard1}
            alt="Feature-1"
          />
        </div>
      </div>

      {/* Feature 1 Container 2 Section */}
      <div className="feature-container">
        <div className="feature-container-img">
          <img
            className="feature-vector-img"
            src={Dashboard2}
            alt="Dashboard2"
          />
        </div>
        <div className="feature-container-text">
          <div className="feature-text-main">
            <p>
              Helping you create{" "}
              <span className="feature-text-colored">detailed </span>
            </p>
            <p className="feature-text-colored">and specific documents</p>
          </div>
          <div className="feature-sub-text">
            <p>Helps you keep PRDs and tech documents</p>
            <p>updated based on the latest discussions</p>
            <p>and decisions</p>
          </div>
        </div>
      </div>

      {/* Feature 2 Section */}
      <div className="feature-2">
        <div className="feature-2-text">
          <p id="feature-2-text-1">FEATURES</p>
          <p id="feature-2-text-2"> Integrates with your </p>
          <p id="feature-2-text-2"> existing tools</p>
        </div>
        <div>
          <img className="feature-2-image" src={Features} alt="Features" />
        </div>
      </div>

      {/* Ready Section */}
      <div className="ready">
        <p id="ready-text-1">Say hello to streamlined productivity,</p>
        <p id="ready-text-2"> Ready to try Falcon? </p>
        <Link to="/waitlist">
          <ButtonWrapper
            id="ready-getstarted-button"
            buttonName="Get Started"
            service="GET_STARTED"
          />
        </Link>
      </div>
    </div>
  );
};
