import { useEffect, useState } from "react";
import Logo from "./../assets/Falcon-AI_logo.svg";
import vector1 from "./../assets/signup-vector-1.svg";
import vector2 from "./../assets/signup-vector-2.svg";
import vector3 from "./../assets/signup-vector-3.svg";
import { handleJoinWaitlist } from "../services/auth";
import { LoadingSpinner } from "../common/Loader";

export const JoinWaitlist: React.FC = () => {
  const vectorItems = [
    { vector: vector1, title: "Elevate", subtitle: "Your Meetings" },
    { vector: vector2, title: "Empower", subtitle: "Your Team" },
    { vector: vector3, title: "Embrace", subtitle: "Your Efficiency" },
  ];
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    const response = await handleJoinWaitlist(email);
    setIsLoading(false);
    if (!response.ok) {
      setError(response.error as string);
      setEmail("");
    } else {
      setEmail("");
      setError("");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="parent-signup">
        <div className="parent-signup-in">
          <div className="signup-left">
            <div className="signup-logo">
              <img className="logo" src={Logo} alt="Logo" />
            </div>
            {vectorItems.map((item, index) => (
              <div className={`signup-left-text`} key={index}>
                <div className="signup-left-vector-container">
                  <img
                    className={`signup-text-vector`}
                    src={item.vector}
                    alt={`Vector`}
                  />
                </div>
                <div className={`text`}>
                  <p className="signup-left-color"> {item.title}</p>
                  <p>{item.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="signup-right">
            <div className="signup-text">Join Waitlist</div>
            <input
              type="email"
              className="email-input"
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            {error && <div className="error-message">{error}</div>}
            <button className="signup-button" onClick={handleClick}>
              {isLoading ? (
                <LoadingSpinner loading={isLoading} className="loader" />
              ) : (
                <div className="signup-button-text">Submit</div>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
