import { useEffect } from "react";

export const Refund: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="parent-refund">
      <div className="heading-refund">
        <h1 id="sub-heading-refund">Refund Policy</h1>
      </div>
      <div className="text-container">
        <p id="refund-text">Once you have made the one-time payment, you will not receive a refund. Please go through the website carefully before you make your decision to buy. If you think there’s been an error in billing, please contact our support team at vaibhav@getfalcon.in and we’ll work with you to find a solution</p>
      </div>
    </div>
  );
};
