import { useEffect } from "react";
import React from "react";

export const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts
  }, []);
  return (
    <div className="parent-page">
      <div className="page-heading">Privacy Policy</div>
      <div className="page-text">
        <p id="text-subhead">
          Getfalcon.in has prepared this Privacy Policy to explain (1) what
          personal information we collect, (2) how we use and share that
          information, and (3) your choices concerning our privacy and
          information practices.
        </p>
        <p id="subheadings">Applicability of this Privacy Policy</p>
        <p id="text-subhead">
          We provide an AI-powered collaboration and productivity tools,
          software and other related technology (the “Services”). This Privacy
          Policy applies to personal information that we collect in connection
          with the Services, our website(s), including https://getfalcon.in/,
          and products and/or services that link to this Privacy Policy.
        </p>
        <p id="text-subhead">
          If you are a customer of Falcon AI, this Privacy Policy does not apply
          to personal information that we process on your behalf (if any) as
          your service provider. Such personal information shall instead be
          governed by the terms and conditions of the separate customer
          agreement or terms of service that you have agreed to with Falcon AI.
          In addition, our Services are designed for businesses and are not
          intended for personal, family, or household use. Accordingly, we treat
          all personal information covered by this Privacy Policy as pertaining
          to individuals acting as business representatives, rather than in
          their personal capacity.
        </p>
        <p id="subheadings">Personal information we collect</p>
        <p id="subheadings">Information you provide to us:</p>
        <ul>
          <li id="text-subhead">
            <span id="text-headings">Business Contact Information:</span> If you
            are a representative of one of our actual or prospective customers,
            suppliers or business partners, we may collect personal information
            about you (such as your name, contact details and role) when
            entering into an agreement with your company and during the course
            of our relationship with your company.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Account information,</span> such as your
            name, email address, and other similar information when you register
            an account with us.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Transaction information, </span>such as
            your credit card or other payment information and authentication
            details when you purchase a subscription for the Services. We do not
            directly process your credit card information and instead rely on
            Stripe as our payment processor.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Feedback or correspondence,</span> such as
            information you provide when you contact us with questions,
            feedback, reviews, or otherwise correspond with us online.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Usage information,</span> such as
            information about how you use the Services and interact with us.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Marketing information,</span> such as your
            preferences for receiving communications about our activities,
            services, and publications, and details about how you engage with
            our communications.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Other information</span> that we may
            collect which is not specifically listed here, but which we will use
            in accordance with this Privacy Policy or as otherwise disclosed at
            the time of collection.
          </li>
        </ul>
        <p id="text-subhead">
          <span id="text-headings">Information received from our users.</span>{" "}
          We may receive your personal information if another user or customer
          adds you to meeting or otherwise provides us with your personal
          information through the service.In such an instance, we may use your
          personal information to contact you on behalf of the user who provided
          us with your personal information (such as to send you a reminder
          about the meeting) or as otherwise described in this Privacy Policy,
          to the extent permitted under applicable laws and regulations.
        </p>
        <p id="text-subhead">
          <span id="text-headings">
            Information we obtain from third parties.{" "}
          </span>
          We may maintain pages on social media platforms, such as Facebook,
          Twitter, Instagram, and other third-party platforms. When you visit or
          interact with our pages on those platforms, the platform provider’s
          privacy policy will apply to your interactions and their collection,
          use and processing of your personal information. You or the platforms
          may provide us with information through the platform, and we will
          treat such information in accordance with this Privacy Policy. We may
          obtain your personal information from other third parties, such as
          marketing partners, publicly-available sources and data providers.
        </p>
        <p>
          <span id="text-headings">Automatic data collection.</span> We and our
          service providers may automatically log information about you, your
          computer or mobile device, and your interaction over time with our
          Services, our communications and other online services, such as:
        </p>
        <ul>
          <li id="text-subhead">
            <span id="text-headings">Device data,</span> such as your computer’s
            or mobile device’s operating system type and version, manufacturer
            and model, browser type, screen resolution, RAM and disk size, CPU
            usage, device type (e.g., phone, tablet), IP address, unique
            identifiers (including identifiers used for advertising purposes),
            language settings, mobile device carrier, radio/network information
            (e.g., WiFi, LTE, 4G), and general location information such as
            city, state or geographic area.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Online activity data,</span> such as pages
            or screens you viewed, how long you spent on a page or screen,
            browsing history, navigation paths between pages or screens,
            information about your activity on a page or screen, access times,
            and duration of access, and whether you have opened our marketing
            emails or clicked links within them.
          </li>
          <li id="text-subhead">
            {" "}
            <span id="text-headings">Email Open/Click Information.</span> We may
            use pixels in our email campaigns that allow us to collect your
            email and IP address as well as the date and time you open an email
            or click on any links in the email that we may send to you.
          </li>
        </ul>
        <p id="subheadings">Use of Large Language Models</p>
        <ul>
          <li id="text-subhead">
            To provide certain features in our service, we may utilize large
            language models (LLMs) - AI systems trained on large datasets.
          </li>
          <li id="text-subhead">
            We do not train LLMs directly on any data provided by users of
            Broadcast. The models we use are pre-trained by vendors and research
            groups on broad public datasets.
          </li>
          <li id="text-subhead">
            We may send limited samples of user data to LLMs solely for
            processing related to the requested service functionality. For
            example, to automatically summarize key points from meeting notes.
            The data is not retained by the LLM providers for model training or
            other purposes.
          </li>
          <li id="text-subhead">
            The LLM services we use contractually prohibit utilizing customer
            data for training their models. We vet these providers to ensure
            they meet our privacy standards.
          </li>
          <li id="text-subhead">
            After processing, results from the LLM are returned without any
            retained user data. We do not store or share any raw user data with
            LLM providers.
          </li>
          <li id="text-subhead">
            We aim to minimize data sharing with third-party systems. If an LLM
            provider were to improperly utilize customer data, we would
            immediately discontinue their use.
          </li>
        </ul>
        <p id="subheadings">Data Sharing and Disclosure</p>
        We do not share your data with third parties except in the following
        cases:{" "}
        <ul>
          <li id="text-subhead">
            With your explicit consent, which is obtained separately from Google
            OAuth or other login provider consent.{" "}
          </li>
          <li id="text-subhead">
            With third-party service providers working on our behalf who are
            vetted for security and privacy compliance. Their access is limited
            to the data needed to provide their service.{" "}
          </li>
          <li id="text-subhead">
            As required by law, such as to comply with regulations or legal
            obligations.{" "}
          </li>
          <li id="text-subhead">
            If required for mergers, acquisitions, or sale of the company
            assets, we may transfer data to the new party, subject to the
            agreements in place.
          </li>
        </ul>
        <p id="subheadings">User Consent</p>
        <ul>
          <li id="text-subhead">
            We obtain your explicit consent before sharing any of your personal
            data with third-party tools or services, such as AI processing. This
            consent is separate from login provider OAuth approval. You have the
            right to decline any data sharing that is not essential for us to
            provide you with the core Broadcast service.
          </li>
        </ul>
        <p id="subheadings">User Control</p>
        <ul>
          <li id="text-subhead">
            You may access, correct, or delete your personal data at any time by
            reaching out to vaibhav@getfalcon.in You may adjust your data
            sharing preferences through your account settings.
          </li>
        </ul>
        <p id="subheadings">
          We use the following tools for automatic data collection:
        </p>
        <ul>
          <li id="text-subhead">
            <span id="text-headings">Cookies,</span> which are text files that
            websites store on a visitor’s device to uniquely identify the
            visitor’s browser or to store information or settings in the browser
            for the purpose of helping you navigate between pages efficiently,
            remembering your preferences, enabling functionality, helping us
            understand user activity and patterns, and facilitating online
            advertising.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Local storage technologies,</span>like
            HTML5, that provide cookie-equivalent functionality but can store
            larger amounts of data, including on your device outside of your
            browser in connection with specific applications.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Google OAuth,</span>Falcon's use and
            transfer of information received from Google APIs to any other app
            will adhere to{" "}
            <a
              className="links"
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            >
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements
          </li>
        </ul>
        <p id="subheadings">How we use your personal information</p>
        <p id="subheadings">To operate our Services:</p>
        <ul>
          <li>
            Provide, operate, maintain, secure and improve our Services. •
            Provide information about our Services. • Communicate with you about
            our Services, including by sending you announcements, updates,
            security alerts, and support and administrative messages. • Respond
            to your requests, questions and feedback.
          </li>
        </ul>
        <p id="text-subhead">
          <span id="text-headings">Marketing and advertising.</span> We may from
          time-to-time send you direct marketing communications as permitted by
          law, including, but not limited to, notifying you of special
          promotions, offers and events via email and in-app notifications. You
          may opt out of our marketing communications as described in the{" "}
          <span id="text-headings">“Opt out of marketing communications”</span>{" "}
          section below.
        </p>
        <p id="text-subhead">
          <span id="text-headings">For research and development.</span> We may
          use your personal information for research and development purposes,
          including to analyze and improve our Services and our business. As
          part of these activities, we may create aggregated, de-identified, or
          other anonymous data from personal information we collect. We make
          personal information into anonymous data by removing information that
          makes the data personally identifiable to you. We may use this
          anonymous data and share it with third parties for our lawful business
          purposes, including to analyze and improve our Services and promote
          our business.
        </p>
        <p id="text-subhead">
          <span id="text-headings">Compliance and protection.</span> We may use
          personal information to:
        </p>
        <ul>
          <li id="text-subhead">
            Comply with applicable laws, lawful requests, and legal process,
            such as to respond to subpoenas or requests from government
            authorities.
          </li>
          <li id="text-subhead">
            Protect our, your or others’ rights, privacy, safety or property
            (including by making and defending legal claims).
          </li>
          <li id="text-subhead">
            Audit our internal processes for compliance with legal and
            contractual requirements and internal policies.
          </li>
          <li id="text-subhead">
            Enforce the terms and conditions that govern our Services.
          </li>
          <li id="text-subhead">
            Prevent, identify, investigate and deter fraudulent, harmful,
            unauthorized, unethical or illegal activity, including cyberattacks
            and identity theft.
          </li>
        </ul>
        <p id="subheadings">How we share your personal information</p>
        <p id="text-subhead">
          <span id="text-headings">Service providers.</span> We may share your
          personal information with third party companies and individuals that
          provide services on our behalf or help us operate our Services (such
          as lawyers, bankers, auditors, insurers, and providers that assist
          with hosting, analytics, email delivery, marketing, and database
          management).
        </p>
        <p id="text-subhead">
          <span id="text-headings">Authorities and others.</span> We may
          disclose your personal information to law enforcement, government
          authorities, and private parties, as we believe in good faith to be
          necessary or appropriate for the compliance and protection purposes
          described above.
        </p>
        <p id="text-subhead">
          <span id="text-headings">Business transfers.</span> We may sell,
          transfer or otherwise share some or all of our business or assets,
          including your personal information, in connection with a business
          transaction (or potential business transaction) such as a corporate
          divestiture, merger, consolidation, acquisition, reorganization or
          sale of assets, or in the event of bankruptcy or dissolution. In such
          a case, we will make reasonable efforts to require the recipient to
          honor this Privacy Policy.
        </p>
        <p id="text-subhead">
          <span id="text-headings">Affiliates:</span> We may share personal
          information with our current and future affiliates, meaning an entity
          that controls, is controlled by, or is under common control with us.
          Our affiliates may use the personal information we share in a manner
          consistent with this Privacy Policy.
        </p>
        <p id="subheadings">Your choices</p>
        <p id="text-subhead">
          In this section, we describe the rights and choices available to all
          users.
        </p>
        <p id="text-subhead">
          Personal information requests:In certain circumstances (including
          based on where you are located), you may have the following rights in
          relation to your personal information:
        </p>
        <ul>
          <li id="text-subhead">
            The right to learn more about what personal information of yours is
            being processed, how and why such information is processed and the
            third parties who have access to such personal information. We have
            made this information available to you without having to request it
            by including it in this Privacy Policy;
          </li>
          <li id="text-subhead">
            The right to access your personal information;
          </li>
          <li id="text-subhead">
            The right to rectify/correct your personal information;
          </li>
          <li id="text-subhead">
            The right to restrict the use of your personal information where
            permitted under applicable law;
          </li>
          <li id="text-subhead">
            The right to request that your personal information is
            erased/deleted where permitted under applicable law;
          </li>
          <li id="text-subhead">
            The right to data portability (i.e. receive your personal
            information or have it transferred to another controller in a
            structured, commonly-used, machine readable format) where permitted
            under applicable law; and
          </li>
          <li id="text-subhead">
            The right to object to processing of your personal information or to
            direct us not to share your personal information with a
            non-affiliated third party where permitted under applicable law.
          </li>
        </ul>
        <p id="text-subhead">
          To make a request, please email us as provided in the{" "}
          <span id="text-headings">“How to Contact Us”</span> section below. We
          may ask for specific information from you to help us confirm your
          identity. We will require authorized agents to confirm their identity
          and authority, in accordance with applicable laws. You are entitled to
          exercise the rights described above free from discrimination.
        </p>
        <p id="text-subhead">
          Please note that in some circumstances, we may not be able to fully
          comply with your request, for example if we are required to retain
          certain information about you to comply with applicable laws and
          regulations or if the information is necessary in order for us to
          provide the services you requested. In particular, we, and our
          collection and processing of your personal information, may be
          governed by laws and regulations on anti-money laundering, fraud
          prevention, taxation and financial services. We will not discriminate
          against you for exercising your rights. We will not deny you access to
          our services, or provide you a lower quality of services if you
          exercise your rights.
        </p>
        <p id="text-subhead">
          <span id="text-headings">Opt out of marketing communications.</span>{" "}
          You may opt out of email communications by following the opt-out or
          unsubscribe instructions at the bottom of the email.
        </p>
        <p id="text-subhead">
          <span id="text-headings">Online tracking opt-out.</span> There are a
          number of ways to opt out of having your online activity and device
          data collected through our Services, which we have summarized below:
        </p>
        <ul>
          <li id="text-subhead">
            <span id="text-headings">Blocking cookies in your browser.</span>{" "}
            Most browsers let you remove or reject cookies. To do this, follow
            the instructions in your browser settings. Many browsers accept
            cookies by default until you change your settings. For more
            information about cookies, including how to see what cookies have
            been set on your device and how to manage and delete them, visit
            www.allaboutcookies.org.
          </li>
          <li id="text-subhead">
            <span id="text-headings">Using privacy plug-ins or browsers.</span>{" "}
            You can block our Services from setting cookies used for
            interest-based ads by using a browser with privacy features or
            installing browser plugins like Privacy Badger, DuckDuckGo, Ghostery
            or uBlock Origin, and configuring them to block third party
            cookies/trackers.
          </li>
        </ul>
        <p id="text-subhead">
          Note that because these opt-out mechanisms are specific to the device
          or browser on which they are exercised, you will need to opt-out on
          every browser and device that you use.
        </p>
        <p id="subheadings">Data Retention</p>
        <p id="text-subhead">
          We may retain your personal information for as long as it is
          reasonably needed in order to maintain and expand our relationship and
          provide you with our services; in order to comply with our legal and
          contractual obligations; or to protect ourselves from any potential
          disputes. To determine the appropriate retention period for personal
          information, we consider the amount, nature, and sensitivity of such
          information, the potential risk of harm from unauthorized use or
          disclosure of such information, the purposes for which we process it,
          and the applicable legal requirements.
        </p>
        <p id="text-subhead">
          As a product user, we archive meetings recorded through the Services
          after one (1) year from creation, and we retain other personal
          information associated with your account as long as you have an active
          account with us, in each case, unless you have requested deletion of
          your data in accordance with this Privacy Policy or as otherwise
          specified in your user agreement with us.
        </p>
        <p id="subheadings">Other sites, mobile applications and services</p>
        <p id="text-subhead">
          Our Services may contain links to other websites, mobile applications,
          and other online services operated by third parties. These links are
          not an endorsement of, or representation that we are affiliated with,
          any third party. In addition, our content may be included on web pages
          or in mobile applications or online services that are not associated
          with us. We do not control third party websites, mobile applications
          or online services, and we are not responsible for their actions.
          Other websites and services follow different rules regarding the
          collection, use and sharing of your personal information. We encourage
          you to read the privacy policies of the other websites and mobile
          applications and online services you use.
        </p>
        <p id="subheadings">Security practices</p>
        <p id="text-subhead">
          We use reasonable organizational, technical and administrative
          measures designed to protect against unauthorized access, misuse,
          loss, disclosure, alteration and destruction of personal information
          we maintain. Unfortunately, data transmission over the Internet cannot
          be guaranteed as completely secure. Therefore, while we strive to
          protect your personal information, we cannot guarantee the security of
          personal information. In the event that we are required to notify you
          about a situation involving your data, we may do so by email or
          telephone to the extent permitted by law.
        </p>
        <p id="subheadings">Children</p>
        <p id="text-subhead">
          Our Services are not intended for children, and we do not collect
          personal information from them. We define “children” as anyone under
          18 years old. If we learn we have collected or received personal
          information from a child without verification of parental consent, we
          will delete the information. If you believe we might have any
          information from or about a child, please contact us at
          vaibhav@getfalcon.in
        </p>
        <p id="subheadings">Changes to this Privacy Policy</p>
        <p id="text-subhead">
          We reserve the right to modify this Privacy Policy at any time. If we
          make material changes to this Privacy Policy, we will notify you by
          updating the date of this Privacy Policy and posting it on our
          Services. We may also provide notification of changes in another way
          that we believe is reasonably likely to reach you, such as via e-mail
          (if you have an account where we have your contact information) or
          another manner through our Services.
        </p>
        <p id="text-subhead">
          Any modifications to this Privacy Policy will be effective upon our
          posting the new terms and/or upon implementation of the new changes on
          our Services (or as otherwise indicated at the time of posting). In
          all cases, your continued use of the Services after the posting of any
          modified Privacy Policy indicates your acceptance of the terms of the
          modified Privacy Policy.
        </p>
        <p id="subheadings">How to contact us</p>
        <p id="text-subhead">
          If you have any questions or concerns, you can reach us by email at
          vaibhav@getfalcon.in
        </p>
      </div>
    </div>
  );
};
