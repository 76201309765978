import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";
import { env } from "./config/env";

Bugsnag.start({
  apiKey: env.bugsnagApiKey,
  plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React);

interface ErrorFallbackProps {
  error: Error;
  resetError: () => void;
}

const MyFallbackComponent: React.FC<ErrorFallbackProps> = ({
  error,
  resetError,
}) => (
  <div>
    <p>An error has occurred in the app:</p>
    <p>{error.message}</p>
    <button onClick={resetError}>Try again</button>
  </div>
);

export default ErrorBoundary
  ? (props: any) => (
      <ErrorBoundary FallbackComponent={MyFallbackComponent} {...props} />
    )
  : React.Fragment;
