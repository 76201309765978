import { useEffect } from "react";

export const TermsConditions: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="parent-page">
      <div className="page-heading">Terms of Service</div>
      <div className="page-text">
        <p id="text-subhead">
          This Agreement consists of the terms and conditions set forth below
          and any Order Forms that reference this Agreement. If you are
          accessing or using the Falcon AI Services on behalf of your company,
          you represent that you are authorized to accept this Agreement on
          behalf of your company, and all references to "you"reference your
          company.
        </p>
        <p id="text-subhead">
          Please note that Falcon AI may modify the terms and conditions of this
          Agreement in accordance with Section 9.4 (Amendment; Waivers).
        </p>
        <p id="text-subhead">
          BY INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT OR ACCESSING OR USING
          THE FALCON AI SERVICES, YOU ARE AGREEING TO BE BOUND BY ALL TERMS,
          CONDITIONS AND NOTICES CONTAINED OR REFERENCED IN THIS AGREEMENT. IF
          YOU DO NOT AGREE TO THIS AGREEMENT, PLEASE DO NOT USE THE FALCON AI
          SERVICE. FOR CLARITY, EACH PARTY EXPRESSLY AGREES THAT THIS AGREEMENT
          IS LEGALLY BINDING UPON IT.
        </p>
        <p className="main-subhead" id="text-headings">
          1. Definitions
        </p>
        <p id="text-subhead">
          1.1 The following terms, when used in this Agreement will have the
          following meanings:
        </p>
        <p id="text-subhead">
          <span id="text-headings">"Affiliates" </span>means an entity that
          directly or indirectly Controls, is Controlled by, or is under common
          Control with another entity, so long as such Control exists. For the
          purposes of this definition, "Control"means beneficial ownership of
          50% or more of the voting power or equity in an entity.
        </p>
        <p id="text-subhead">
          <span id="text-headings">"Confidential Information" </span>means any
          information or data disclosed by either party that is marked or
          otherwise designated as confidential or proprietary or that should
          otherwise be reasonably understood to be confidential in light of the
          nature of the information and the circumstances surrounding
          disclosure. However, "Confidential Information"will not include any
          information which (a) is in the public domain through no fault of
          receiving party; (b) was properly known to receiving party, without
          restriction, prior to disclosure by the disclosing party; (c) was
          properly disclosed to receiving party, without restriction, by another
          person with the legal authority to do so; or (d) is independently
          developed by the receiving party without use of or reference to the
          disclosing party's Confidential Information.
        </p>
        <p id="text-subhead">
          <span id="text-headings">"Documentation" </span>means the printed and
          digital instructions, on-line help files, technical documentation and
          user manuals made available by Falcon AI for the Falcon AI Service.
        </p>
        <p id="text-subhead">
          <span id="text-headings">"Order Form"</span> means any Falcon AI order
          form, online sign-up, or subscription flow that references this
          Agreement.
        </p>
        <p id="text-subhead">
          <span id="text-headings">"Falcon AI Service" </span>means Falcon AI's
          collaboration and productivity tools, including the related web
          extensions and software applications made available by Falcon AI.
        </p>
        <p className="main-subhead" id="text-headings">
          2. Falcon AI Service
        </p>
        <p id="text-subhead">
          <span id="text-headings">2.1 Account Registration.</span> Customer may
          need to register for a Falcon AI account in order to use the Falcon AI
          Service. Account information must be accurate, current, and complete.
          Customer agrees to keep this information up-to-date so that Falcon AI
          may send notices, statements, and other information by email or
          through Customer's account. Customer must ensure that any user IDs,
          passwords, and other access credentials for the Falcon AI Service are
          kept strictly confidential and not shared with any unauthorized
          person.
        </p>
        <p id="text-subhead">
          <span id="text-headings"> 2.2 Access to Falcon AI Service.</span>{" "}
          Subject to the terms and conditions of this Agreement, Falcon AI
          hereby grants Customer a non-exclusive, non-transferrable,
          non-sublicensable right to access and use the Falcon AI Service for
          its internal business purposes.
        </p>
        <p id="text-subhead">
          <span id="text-headings">2.3 Customer Limitations.</span>Customer will
          not directly or indirectly: (a) reverse engineer, decompile,
          disassemble, modify, create derivative works of or otherwise create,
          attempt to create or derive, or permit or assist any third party to
          create or derive, the source code underlying the Falcon AI Service;
          (b) attempt to probe, scan or test the vulnerability of the Falcon AI
          Service, breach the security or authentication measures of the Falcon
          AI Service without proper authorization or willfully render any part
          of the Falcon AI Service unusable; (c) use or access the Falcon AI
          Service to develop a product or service that is competitive with
          Falcon AI's products or services or engage in competitive analysis or
          benchmarking; (d) transfer, distribute, resell, lease, license, or
          assign the Falcon AI Service or otherwise offer the Falcon AI Service
          on a standalone basis; or (e) otherwise use the Falcon AI Service in
          violation of applicable law (including any export law) or outside the
          scope expressly permitted hereunder and in the applicable Order Form
          (collectively, the "License Restrictions" ).
        </p>
        <p id="text-subhead">
          <span id="text-headings">2.4 Customer Responsibilities.</span>{" "}
          Customer will (i) be responsible for all use of the Falcon AI Service
          under its account, (ii) use commercially reasonable efforts to prevent
          unauthorized access to or use of the Falcon AI Service and notify
          Falcon AI promptly of any such unauthorized access or use or any other
          known or suspected breach of security or misuse of the Falcon AI
          Service and (iii) be responsible for obtaining and maintaining any
          equipment, software and ancillary services needed to connect to,
          access or otherwise use the Falcon AI Service, including as set forth
          in the Documentation. Customer will be solely responsible for its
          failure to maintain such equipment, software and services, and Falcon
          AI will have no liability for such failure (including under any
          service level agreement).
        </p>
        <p id="text-subhead">
          <span id="text-headings">
            2.5 Beta Releases and Free Access Subscriptions.
          </span>{" "}
          Falcon AI may provide Customer with the Falcon AI Service for free or
          on a trial basis (a "Free Access Subscriptions" or with "alpha" "beta"
          or other early-stage Falcon AI Services, integrations, or features
          ("Beta Releases", which are optional for Customer to use. Falcon AI
          makes no promises that future versions of Beta Releases or Free Access
          Subscriptions will be released or will be made available under the
          same commercial or other terms. Falcon AI may terminate Customer's
          right to use any Free Access Subscriptions or Beta Releases at any
          time in Falcon AI's sole discretion, without liability. WITH RESPECT
          TO BETA RELEASES, CUSTOMER ACKNOWLEDGES AND AGREES THAT BETA RELEASES
          MAY NOT BE COMPLETE OR FULLY FUNCTIONAL AND MAY CONTAIN BUGS, ERRORS,
          OMISSIONS, AND OTHER PROBLEMS FOR WHICH FALCON AI WILL NOT BE
          RESPONSIBLE. ACCORDINGLY, ANY USE OF BETA RELEASES ARE AT CUSTOMER'S
          SOLE RISK NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN.
        </p>
        <p id="text-subhead">
          <span id="text-headings">2.6 Affiliates.</span> Any Affiliate of
          Customer will have the right to enter into an Order Form executed by
          such Affiliate and Falcon AI and this Agreement will apply to each
          such Order Form as if such Affiliate were a signatory to this
          Agreement. With respect to such Order Forms, such Affiliate becomes a
          party to this Agreement and references to Customer in this Agreement
          are deemed to be references to such Affiliate. Each Order Form is a
          separate obligation of the Customer entity that executes such Order
          Form, and no other Customer entity has any liability or obligation
          under such Order Form.
        </p>
        <p className="main-subhead" id="text-headings">
          3. Fees
        </p>
        <p id="text-subhead">
          <span id="text-headings">3.1 Fees.</span> To the extent the Falcon AI
          Service is made available for a fee, Customer will pay Falcon AI for
          amounts as set forth in the Order Form. Except as otherwise specified
          herein or in any applicable Order Form, (a) fees are quoted and
          payable in United States dollars and (b) payment obligations are
          non-cancelable and non-pro-ratable for partial months, and fees paid
          are non-refundable.
        </p>
        <p id="text-subhead">
          <span id="text-headings">3.2 Credit Card Payment Terms.</span> If
          Customer elects to pay via credit card, then Customer is responsible
          for either (a) enabling auto-recharge on Customer's payment instrument
          or (b) ensuring that Customer's payment instrument has a sufficient
          positive balance to cover all fees due. If, for any reason, Customer
          has a negative balance on its payment account, then Falcon AI reserves
          the right to suspend access to the paid version until all fees are
          paid in full.
        </p>
        <p id="text-subhead">
          <span id="text-headings">3.3 Invoicing Payment Terms. </span>If
          Customer elect to receive invoices and Falcon AI approves Customer for
          the same, then invoices will be sent to via email in accordance with
          the Order Form. Except as otherwise set forth in an Order Form,
          Customer will make all of the undisputed fees hereunder within thirty
          (30) days of the date of the invoice.
        </p>
        <p id="text-subhead">
          <span id="text-headings">3.4 Suspension; Late Payment. </span>Falcon
          AI may suspend access to the Falcon AI Service immediately upon notice
          if Customer fails to pay any amounts hereunder at thirty (30) days
          past the applicable due date. If Falcon AI has not received payment
          within thirty (30) days after the applicable due date, interest will
          accrue on past due amounts at the rate of one percent (1%) per month,
          but in no event greater than the highest rate of interest allowed by
          law, calculated from the date such amount was due until the date that
          payment is received by Falcon AI.
        </p>
        <p id="text-subhead">
          <span id="text-headings">3.5 Taxes. </span>All amounts payable
          hereunder are exclusive of any sales, use and other taxes or duties,
          however designated (collectively "Taxes"). Customer will be solely
          responsible for payment of all Taxes, except for those taxes based on
          the income of Falcon AI. Customer will not withhold any taxes from any
          amounts due to Falcon AI.
        </p>
        <p className="main-subhead" id="text-headings">
          4. Proprietary Rights and Confidentiality
        </p>
        <p id="text-subhead">
          <span id="text-headings">4.1 Customer Data.</span>Customer shall
          retain all right, title and interest to all data and information
          provided to Falcon AI in connection with its use of the Falcon AI
          Service ("Customer Data"). Falcon AI will maintain a security program
          materially in accordance with industry standards that is designed to
          (i) ensure the security and integrity of Customer Data; (ii) protect
          against threats or hazards to the security or integrity of Customer
          Data; and (iii) prevent unauthorized access to Customer Data. Customer
          warrants that it has all rights necessary to provide any information,
          data or other materials that it provides hereunder, and to permit
          Falcon AI to use the same as contemplated hereunder.
        </p>
        <p id="text-subhead">
          <span id="text-headings">4.2 Proprietary Rights.</span> As between the
          parties, Falcon AI exclusively owns all right, title and interest in
          and to the Falcon AI Service, System Data and Falcon AI's Confidential
          Information. "System Data"means data collected by Falcon AI regarding
          the Falcon AI Service that may be used to generate logs, statistics or
          reports regarding the performance, availability, usage, integrity or
          security of the Falcon AI Service.
        </p>
        <p id="text-subhead">
          <span id="text-headings">4.3 Feedback.</span>Customer may from time to
          time provide Falcon AI suggestions or comments for enhancements or
          improvements, new features or functionality or other feedback
          ("Feedback") with respect to the Falcon AI Service. Falcon AI will
          have full discretion to determine whether or not to proceed with the
          development of any requested enhancements, new features or
          functionality. Falcon AI will have the full, unencumbered right,
          without any obligation to compensate or reimburse Customer, to use,
          incorporate and otherwise fully exercise and exploit any such Feedback
          in connection with its products and services.
        </p>
        <p id="text-subhead">
          <span id="text-headings">4.4 Confidentiality.</span> Each party agrees
          that it will use the Confidential Information of the other party
          solely in accordance with the provisions of this Agreement and it will
          not disclose the same directly or indirectly, to any third party
          without the other party's prior written consent, except as otherwise
          permitted hereunder. However, either party may disclose Confidential
          Information (a) to its employees, officers, directors, attorneys,
          auditors, financial advisors and other representatives who have a need
          to know and are legally bound to keep such information confidential by
          confidentiality obligations consistent with those of this Agreement;
          and (b) as required by law (in which case the receiving party will
          provide the disclosing party with prior written notification thereof,
          will provide the disclosing party with the opportunity to contest such
          disclosure, and will use its reasonable efforts to minimize such
          disclosure to the extent permitted by applicable law. Neither party
          will disclose the terms of this Agreement to any third party, except
          that either party may confidentially disclose such terms to actual or
          potential lenders, investors or acquirers. Each party agrees to
          exercise due care in protecting the Confidential Information from
          unauthorized use and disclosure. In the event of actual or threatened
          breach of the provisions of this Section or the License Restrictions,
          the non-breaching party will be entitled to seek immediate injunctive
          and other equitable relief, without waiving any other rights or
          remedies available to it. Each party will promptly notify the other in
          writing if it becomes aware of any violations of the confidentiality
          obligations set forth in this Agreement.
        </p>
        <p className="main-subhead" id="text-headings">
          5. Warranties and Disclaimers
        </p>
        <p id="text-subhead">
          <span id="text-headings">5.1 Performance Warranty.</span> If Customer
          is using the Falcon AI Service through a paid subscription, Falcon AI
          warrants that the Falcon AI Service will conform in all material
          respects with the Documentation. In the event of a breach of the
          foregoing warranty, Falcon AI will use commercially reasonable efforts
          correct the deficiency at no additional charge to Customer. The
          remedies set forth in this section will be Customer's sole remedy and
          Falcon AI's sole liability for breach of such warranty.
        </p>
        <p id="text-subhead">
          <span id="text-headings">5.2 DISCLAIMERS.</span> EXCEPT AS EXPRESSLY
          SET FORTH HEREIN, Falcon AI DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS
          OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, TITLE,
          NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE. FALCON AI DOES
          NOT REPRESENT OR WARRANT THAT THE FALCON AI SERVICE WILL BE
          ERROR-FREE, AND FALCON AI EXPRESSLY DISCLAIMS ANY WARRANTY AS TO THE
          ACCURACY OR COMPLETENESS OF ANY INFORMATION OR DATA ACCESSED OR USED
          IN CONNECTION WITH THE FALCON AI SERVICE. FALCON AI IS NOT RESPONSIBLE
          OR LIABLE FOR ANY PRODUCTS OR SERVICES NOT PROVIDED BY FALCON AI, AND
          DOES NOT GUARANTEE THE CONTINUED AVAILABILITY THEREOF OR ANY
          INTEGRATION THEREWITH, AND MAY CEASE MAKING ANY SUCH INTEGRATION
          AVAILABLE IN ITS SOLE DISCRETION.
        </p>
        <p className="main-subhead" id="text-headings">
          6. Indemnification
        </p>
        <p id="text-subhead">
          <span id="text-headings"> 6.1 Indemnity by Falcon AI.</span> Falcon AI
          will defend Customer against any claim, demand, suit, or proceeding
          ("Claim") made or brought against Customer by a third party alleging
          that the use of the Falcon AI Service as permitted hereunder infringes
          or misappropriates a United States patent, copyright or trade secret
          and will indemnify Customer for any damages finally awarded against
          Customer (or any settlement approved by Falcon AI) in connection with
          any such Claim. If the use of the Falcon AI Service by Customer has
          become, or in Falcon AI's opinion is likely to become, the subject of
          any claim of infringement, Falcon AI may at its option and expense (i)
          procure for Customer the right to continue using and receiving the
          Falcon AI Service as set forth hereunder; (ii) replace or modify the
          Falcon AI Service to make it non-infringing (with comparable
          functionality); or (iii) if the options in clauses (i) or (ii) are not
          reasonably practicable, terminate this Agreement and provide a pro
          rata refund of any prepaid fees corresponding to the terminated
          portion of the applicable subscription term. Falcon AI will have no
          liability or obligation with respect to any Claim if such Claim is
          caused in whole or in part by (A) Customer's use of any third party
          products and services that interoperate with the Falcon AI Service;
          (B) use of the Falcon AI Service by Customer not in accordance with
          this Agreement; (C) modification of the Falcon AI Service by any party
          other than Falcon AI without Falcon AI's express consent; (D) Customer
          Data or Customer's Confidential Information or (E) the combination,
          operation or use of the Falcon AI Service with other applications,
          portions of applications, product(s) or services where the Falcon AI
          Service would not by itself be infringing (clauses (A) through (E),
          "Excluded Claims). This Section states Falcon AI's sole and exclusive
          liability and obligation, and Customer's exclusive remedy, for any
          claim of any nature related to infringement or misappropriation of
          intellectual property.
        </p>
        <p id="text-subhead">
          <span id="text-headings"> 6.2 Indemnification by Customer.</span>{" "}
          Customer will defend Falcon AI against any Claim made or brought
          against Falcon AI by a third party arising out of any Excluded Claims,
          and Customer will indemnify Falcon AI for any damages finally awarded
          against Falcon AI (or any settlement approved by Customer) in
          connection with any such Claim.
        </p>
        <p id="text-subhead">
          <span id="text-headings">6.3 Indemnification Procedure.</span> Each
          party's indemnification obligations are contingent upon the
          indemnified party providing the indemnifying party with (a) prompt
          written notice of the Claim; (b) the sole and exclusive right to
          control the defense and settlement of the Claim (provided that the
          indemnifying party may not settle any Claim without the indemnified
          party's prior written consent, not to be unreasonably withheld,
          provided further that consent shall not be required if it
          unconditionally releases the indemnified party of all liability); and
          (c) reasonable cooperation in connection with the Claim.
        </p>
        <p className="main-subhead" id="text-headings">
          7. Limitation of Liability
        </p>
        <p id="text-subhead">
          UNDER NO LEGAL THEORY, WHETHER IN TORT, CONTRACT, OR OTHERWISE, WILL
          (A) EITHER PARTY BE LIABLE TO THE OTHER UNDER THIS AGREEMENT FOR ANY
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF
          ANY CHARACTER, INCLUDING DAMAGES FOR LOSS OF GOODWILL, LOST PROFITS,
          LOST SALES OR BUSINESS, WORK STOPPAGE, COMPUTER FAILURE OR
          MALFUNCTION, LOST CONTENT OR DATA, EVEN IF A REPRESENTATIVE OF SUCH
          PARTY HAS BEEN ADVISED, KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY
          OF SUCH DAMAGES, OR (B) Falcon AI BE LIABLE FOR ANY AGGREGATE DAMAGES,
          COSTS, OR LIABILITIES IN EXCESS OF THE AMOUNTS PAID BY CUSTOMER UNDER
          THE APPLICABLE ORDER FORM DURING THE TWELVE (12) MONTHS PRECEDING THE
          INCIDENT OR CLAIM.
        </p>
        <p className="main-subhead" id="text-headings">
          8. Term; Termination
        </p>
        <p id="text-subhead">
          <span id="text-headings"> 8.1 Paid Subscriptions.</span> If Customer
          is accessing the Falcon AI Service via a paid subscription, this
          Agreement will be in effect for the subscription term set forth in the
          Order Form and automatically renew for additional, successive renewal
          terms of equal length unless either party provides the other party
          with written notice of non-renewal at least (30) days'before the end
          of the then-current renewal term. In addition, each party may each
          terminate this Agreement if the other party fails to cure any material
          breach of this Agreement (including a failure to pay undisputed fees)
          within thirty (30) days after written notice detailing the breach.
        </p>
        <p id="text-subhead">
          <span id="text-headings">8.2 Free Access Subscriptions.</span> If
          Customer is accessing the Falcon AI Service via a Free Access
          Subscription, each party may terminate this Agreement upon written
          notice to the other party.
        </p>
        <p id="text-subhead">
          <span id="text-headings">8.3 Survival.</span> Upon termination of this
          Agreement all rights and obligations will immediately terminate except
          that any terms or conditions that by their nature should survive such
          termination will survive, including the License Restrictions and terms
          and conditions relating to proprietary rights and confidentiality,
          disclaimers, indemnification, limitations of liability and termination
          and the general provisions below.
        </p>
        <p className="main-subhead" id="text-headings">
          9. General
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.1 Export Compliance.</span> Each party will
          comply with the export laws and regulations of the United States,
          European Union and other applicable jurisdictions in providing and
          using the Falcon AI Service.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.2 Publicity.</span> Customer agrees that
          Falcon AI may refer to Customer's name and trademarks in Falcon AI's
          marketing materials, website, and in any other publicity (e.g., press
          releases, customer references and case studies). If Customer does not
          agree to Falcon AI's use of Customer's name or trademark in Falcon
          AI's materials, Customer may opt-out of such use by providing written
          notice to Falcon AI at any time.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.3 Assignment; Delegation.</span> Neither
          party hereto may assign or otherwise transfer this Agreement, in whole
          or in part, without the other party's prior written consent, except
          that either party may assign this Agreement without consent to a
          successor to all or substantially all of its assets or business
          related to this Agreement. Any attempted assignment, delegation, or
          transfer by either party in violation hereof will be null and void.
          Subject to the foregoing, this Agreement will be binding on the
          parties and their successors and assigns.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.4 Amendment; Waiver.</span> Falcon AI
          reserves the right in its sole discretion and at any time and for any
          reason to modify this Agreement. With respect to each Order Form, any
          modifications to this Agreement, shall become effective upon the date
          of Customer's next renewal of such Order Form. It is Customer's
          responsibility to review this Agreement from time to time for any
          changes or modifications. If Customer does not agree to the modified
          Agreement, Customer may provide notice of Customer's non-renewal at
          any point prior to the Customer's next renewal. No waiver by either
          party of any breach or default hereunder shall be deemed to be a
          waiver of any preceding or subsequent breach or default. . Any such
          waiver will be only to the specific provision and under the specific
          circumstances for which it was given, and will not apply with respect
          to any repeated or continued violation of the same provision or any
          other provision. Failure or delay by either party to enforce any
          provision of this Agreement will not be deemed a waiver of future
          enforcement of that or any other provision. The section headings used
          herein are for convenience only and shall not be given any legal
          import
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.5 Relationship.</span> Nothing contained
          herein will in any way constitute any association, partnership,
          agency, employment or joint venture between the parties hereto, or be
          construed to evidence the intention of the parties to establish any
          such relationship. Neither party will have the authority to obligate
          or bind the other in any manner, and nothing herein contained will
          give rise or is intended to give rise to any rights of any kind to any
          third parties.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.6 Unenforceability.</span> If a court of
          competent jurisdiction determines that any provision of this Agreement
          is invalid, illegal, or otherwise unenforceable, such provision will
          be enforced as nearly as possible in accordance with the stated
          intention of the parties, while the remainder of this Agreement will
          remain in full force and effect and bind the parties according to its
          terms.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.7 Governing Law.</span> This Agreement will
          be governed by the laws of the State of California, exclusive of its
          rules governing choice of law and conflict of laws. This Agreement
          will not be governed by the United Nations Convention on Contracts for
          the International Sale of Goods.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.8 Notices.</span> Any notice required or
          permitted to be given hereunder will be given in writing by personal
          delivery, certified mail, return receipt requested, or by overnight
          delivery. Notices to the Customer may be sent to the address listed on
          the Customer's applicable Order Form or email address provided by
          Customer when Customer creates its Falcon AI Service account.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.9 Entire Agreement.</span> This Agreement
          comprises the entire agreement between Customer and Falcon AI with
          respect to its subject matter, and supersedes all prior and
          contemporaneous proposals, statements, sales materials or
          presentations and agreements (oral and written). No oral or written
          information or advice given by Falcon AI, its agents or employees will
          create a warranty or in any way increase the scope of the warranties
          in this Agreement.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.10 Force Majeure.</span> Neither Party will
          be deemed in breach hereunder for any cessation, interruption or delay
          in the performance of its obligations due to causes beyond its
          reasonable control ("Force Majeure Event"), including earthquake,
          flood, or other natural disaster, act of God, labor controversy, civil
          disturbance, terrorism, war (whether or not officially declared),
          cyber attacks (e.g., denial of service attacks), or the inability to
          obtain sufficient supplies, transportation, or other essential
          commodity or service required in the conduct of its business, or any
          change in or the adoption of any law, regulation, judgment or decree.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9.11 Government Terms.</span> Falcon AI
          provides the Falcon AI Service, including related software and
          technology, for ultimate federal government end use solely in
          accordance with the terms of this Agreement. If Customer is an agency,
          department, or other entity of any government, the use, duplication,
          reproduction, release, modification, disclosure, or transfer of the
          Falcon AI Service, or any related documentation of any kind, including
          technical data, software, and manuals, is restricted by the terms of
          this Agreement. All other use is prohibited and no rights than those
          provided in this Agreement are conferred. The Falcon AI Service was
          developed fully at private expense.
        </p>
        <p id="text-subhead">
          <span id="text-headings">9. 12 Interpretation.</span> For purposes
          hereof, "including"means "including without limitation"
        </p>
      </div>
    </div>
  );
};
