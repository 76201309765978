import { ButtonWrapper } from "../common/buttonWrapper";
import Dashboard from "./../assets/Dashboard 9.svg";
import { Link } from "react-router-dom";
import "./../styles/_home.scss";
import "./../styles/_userNotFound.scss";

export const UserNotFound: React.FC = () => {
  return (
    <div>
      <div className="text">
        <p className="text-line-1">
          Welcome to Falcon AI! You're on our waitlist now. We'll reach out soon
          to get you started. Thanks for your interest!
        </p>
        <p className="text-line-colored">
          Book a Demo below to get started with Falcon AI!
        </p>
      </div>
      <div className="book-demo">
        <Link to="https://calendly.com/mrvaibhav98/connect" target="blank">
          <ButtonWrapper
            id="BookDemo"
            buttonName="Book Demo"
            service="BOOK_DEMO"
          />
        </Link>
      </div>
      <div className="dasboard-image">
        <img className="dashboard" src={Dashboard} alt="Dashboard" />
      </div>
    </div>
  );
};
