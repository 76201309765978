import React from "react";
import mixpanelService from "../services/mixpanel";

type ButtonWrapperProps = {
  className?: string;
  id?: string;
  buttonName: string;
  service: string;
  onClick?: () => void;
};

export const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  id,
  buttonName,
  service,
  className,
  onClick,
}) => {
  const handleClick = () => {
    mixpanelService.track(service);
    if (onClick) onClick(); // Call the onClick prop if provided
  };

  return (
    <div>
      <button id={id} onClick={handleClick} className={className}>
        {buttonName}
      </button>
    </div>
  );
};
