import axios, { AxiosRequestConfig } from 'axios';

const apiCall = async (method: 'get' | 'post' | 'put' | 'patch' | 'delete', url: string, headers?: any, data?: any) => {
  const config: AxiosRequestConfig = {
    method,
    url,
    headers,
    data,
    withCredentials: true,
  };
  
  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    console.error(`Error during API call: ${error}`);
    throw error;
  }
};

const api = {
  get: async (url: string, headers?: any) => {
    try {
      return await apiCall('get', url, headers);
    } catch (error) {
      throw error;
    }
  },

  post: async (url: string, headers: any, data: any) => {
    try {
      return await apiCall('post', url, headers, data);
    } catch (error) {
      throw error;
    }
  },

  put: async (url: string, headers: any, data: any) => {
    try {
      return await apiCall('put', url, headers, data);
    } catch (error) {
      throw error;
    }
  },

  patch: async (url: string, headers: any, data: any) => {
    try {
      return await apiCall('patch', url, headers, data);
    } catch (error) {
      throw error;
    }
  },

  delete: async (url: string, headers: any) => {
    try {
      return await apiCall('delete', url, headers);
    } catch (error) {
      throw error;
    }
  }
};

export default api;