
import api from './api';
import { env } from "../config/env";
import { Dispatch, SetStateAction } from "react";
import { AxiosResponse } from "axios";

interface HandleGoogleLoginProps {
  setProgress: Dispatch<SetStateAction<number>>;
}

export const handleGoogleLogin = async ({ setProgress }: HandleGoogleLoginProps) => {
  try {
    const baseUrl: string = env.apiUserManagementBaseUrl;
    const apiUrl = baseUrl + "/auth/accounts/google/login/";
    setProgress(30);
    const response = await api.get(apiUrl);
    setProgress(60);
    if(typeof response !== 'object' || response === null){
      return null;
    }
    const data = (response as AxiosResponse<any, any>).data  ;
    if(data){
      setProgress(100);
      window.location.href = data.url;
    }else{
    }
  } catch (error) {
  }
};

// export const handleJoinWaitlist = async (email: string) => {
//   try {
//     const baseUrl: string = env.apiUserManagementBaseUrl;
//     const apiUrl = baseUrl + "/auth/waitlist/join";
//     const response = await api.post(apiUrl, {}, {email});
//     return {response: response, ok: true}
//   } catch (error: any) {
//     return {error: error.error, ok: false}
//   }
// };

export interface WaitlistResponse {
  response?: any;
  error?: string;
  ok: boolean;
}

export const handleJoinWaitlist = async (email: string): Promise<WaitlistResponse> => {
  try {
    const baseUrl: string = env.apiUserManagementBaseUrl;
    const apiUrl = baseUrl + "/auth/waitlist/join";
    const response = await api.post(apiUrl, {}, {email});

    return { response: response.data, ok: true };
  } catch (error: any) {
    let errorMessage = 'An unexpected error occurred';
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage = error.response.data.error;
    }

    return { error: errorMessage, ok: false };
  }
};