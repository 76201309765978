import Dash from "./../assets/support_dashboard.svg";
import { useEffect } from "react";
export const Support: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="parent-support">
      <div className="heading">
        <h1 id="heading-text">Get in Touch</h1>
      </div>
      <div className="body">
        <p id="sub-heading">
          Add Falcon AI to your standup meetings and get intelligent summaries
          that surface action items, key decisions and connect the dots.
          <div>
            <br />
            <ul className="sub-heading-list">
              <li>Add bot@getfalcon.in to your calendar invite.</li>
              <li>Connect to Slack to receive summaries in Slack</li>
              <li> Allow it into the meeting from the waiting room</li>
              <li>
                After the meeting, the engineering manager will get a summary of the
                standup, with each engineer's updates and action items.
              </li>
              <li>
                Engineering manager can edit this summary if needed, and the click
                on “Approve” after which it will be sent to the team slack channel.
              </li>
            </ul>
          </div>
        </p>
        
      </div>
      <div className="image">
        <img className="image-slack" src={Dash} alt="Vector" />
      </div>
      <div className="text-support">
        <p id="text-support-1">More Questions?</p>
        <p id="text-support-2">
          For any further questions, please feel free to contact
          vaibhav@getfalcon.in
        </p>
      </div>
    </div>
  );
};
