import React, { useEffect } from "react";
import { Home } from "./pages/Home";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { RouteType, SignupOrLogin } from "./pages/SignupOrLogin";
import { Support } from "./pages/Support";
import { PrivacyPolicy } from "./pages/Privacy";
import { Pricing } from "./pages/Pricing";
import { Refund } from "./pages/Refund";
import { TermsConditions } from "./pages/TermsOfService";
import { AppLayout } from "./common/AppLayout";
import { UserNotFound } from "./pages/UserNotFound";
import { ComponentType } from "react";
import GuestRouteWrapper from "./common/GuestRouteWrapper";
import { JoinWaitlist } from "./pages/JoinWaitlist";
import { Integrations } from "./pages/Integrations";

function App() {
  return (
    <Routes>
      <Route path="/" element={<GuestRouter component={Home} />} />
      <Route path="/home" element={<GuestRouter component={Home} />} />
      <Route
        path="/signup"
        element={
          <GuestRouter
            component={SignupOrLogin}
            routerProps={{ routeType: RouteType.Signup }}
          />
        }
      />
      <Route
        path="/login"
        element={
          <GuestRouter
            component={SignupOrLogin}
            routerProps={{ routeType: RouteType.Login }}
          />
        }
      />
      <Route
        path="/waitlist"
        element={<GuestRouter component={JoinWaitlist} />}
      />
      <Route path="/support" element={<AppRouter Component={Support} />} />
      <Route
        path="/privacy-policy"
        element={<AppRouter Component={PrivacyPolicy} />}
      />
      <Route path="/refund-policy" element={<AppRouter Component={Refund} />} />
      <Route
        path="/termsofservice"
        element={<AppRouter Component={TermsConditions} />}
      />
      <Route path="/pricing" element={<AppRouter Component={Pricing} />} />
      <Route
        path="/usernotfound"
        element={<AppRouter Component={UserNotFound} />}
      />
      <Route path="/deck" element={<RedirectToDeck />} />
      <Route path="/integrations" element={<Integrations />} />
    </Routes>
  );
}

interface AppRouterProps {
  Component: React.ElementType;
}

const AppRouter: React.FC<AppRouterProps> = ({ Component }) => {
  return (
    <AppLayout>
      <Component />
    </AppLayout>
  );
};

interface GuestRouterProps<T extends object> {
  component: ComponentType<T>;
  routerProps?: T;
}

function GuestRouter<T extends object>({
  component: Component,
  routerProps,
}: GuestRouterProps<T>) {
  const props = routerProps || ({} as T); // Use type assertion here
  return (
    <GuestRouteWrapper>
      <AppLayout>
        <Component {...props} />
      </AppLayout>
    </GuestRouteWrapper>
  );
}
const RedirectToDeck = () => {
  useEffect(() => {
    window.location.href = "https://docsend.com/view/6mpe8hwdbre4vp5w";
  }, []);

  return null;
};

export default App;
