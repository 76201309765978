import mixpanel from "mixpanel-browser";
import { env } from "./../config/env";
import { eventNamesMapping } from "./../config/mixpanel";

const MIXPANEL_TOKEN = env.mixpanelToken;
const APP_DEBUG = env.appDebug;
class MixpanelService {
  static instance: any;
  constructor() {
    if (typeof window !== "undefined" && !MixpanelService.instance) {
      this.initialize();
      MixpanelService.instance = this;
    }

    return MixpanelService.instance;
  }

  initialize() {
    mixpanel.init(`${MIXPANEL_TOKEN}`, {
      debug: APP_DEBUG,
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  track(eventName: string, properties = {}) {
    if (typeof window !== "undefined") {
      this.initialize();
      mixpanel.track(eventNamesMapping[eventName], properties);
    }
  }

  identify(userId: string | undefined) {
    if (typeof window !== "undefined") {
      mixpanel.identify(userId);
    }
  }
}

const mixpanelService = new MixpanelService();
Object.freeze(mixpanelService);

export default mixpanelService;
