import { useEffect } from "react";
import { ButtonWrapper } from "../common/buttonWrapper";
import { Link } from "react-router-dom";

type PricingPlan = {
  name: string;
  price: string;
  period: string;
  description: string;
  features: string[];
  buttonText: string;
  buttonLink: string;
  buttonColor: string;
  buttonService: string;
};

type CardProps = {
  name: string;
  price: string;
  period: string;
  description: string;
  features: string[];
  buttonText: string;
  buttonLink: string;
  buttonColor: string;
  buttonService: string;
};

const pricingPlans: PricingPlan[] = [
  {
    name: "Free",
    price: "$0",
    period: "/ contributor /month",
    description: "No credit card required.",
    features: [
      "Unlimited users",
      "Up to 5 meeting hours/month",
      "Create AI Meeting notes",
      "Quickly create tasks",
      // "PR & Contributor statistics",
    ],
    buttonText: "Get Started",
    buttonLink: "/waitlist",
    buttonColor: "#61FF8E",
    buttonService: "GET_STARTED",
  },
  {
    name: "Team",
    price: "$99",
    period: "/ team /month",
    description: "For a team of upto 20 members",
    features: [
      "Everything in Free plan",
      "Reminders*",
      "Sync to JIRA*",
      "Milestone Analysis*",
      "More app integrations*",
    ],
    buttonText: "Coming Soon",
    buttonLink: "#",
    buttonColor: "#EDF2FF",
    buttonService: "COMING_SOON",
  },
  {
    name: "Enterprise",
    price: "",
    period: "Contact Sales",
    description: "Features loved by management.",
    features: [
      "Everything in Team",
      "Advanced analytics & reporting*",
      "Customizable workflows*",
      "Dedicated support*",
    ],
    buttonText: "Coming Soon",
    buttonLink: "#",
    buttonColor: "#EDF2FF",
    buttonService: "COMING_SOON",
  },
];

const Card: React.FC<CardProps> = ({
  name,
  price,
  period,
  description,
  features,
  buttonText,
  buttonLink,
  buttonColor,
  buttonService,
}) => (
  <div className="card">
    <div className="card-header">
      <div className="title">
        <strong>{name}</strong>
      </div>
      <div className="description">{description}</div>
    </div>
    <div className="card-price">
      <div className="title">{price}</div>
      <div className="description">{period}</div>
    </div>
    <div className="card-features">
      {features.map((feature, index) => (
        <div className="feature" key={index}>
          <svg viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <p>{feature}</p>
        </div>
      ))}
    </div>
    <div className="card-action">
      <Link to={buttonLink}>
        <ButtonWrapper
          id={buttonService}
          buttonName={buttonText}
          service={buttonService}
          className={`action-button ${
            buttonService === "COMING_SOON" ? "coming-soon" : ""
          }`}
        />
      </Link>
    </div>
  </div>
);

export const Pricing: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="parent">
      <h1 id="pricing">Pricing</h1>
      <div className="pricing-container">
        {pricingPlans.map((plan, index) => (
          <Card
            key={index}
            name={plan.name}
            price={plan.price}
            period={plan.period}
            description={plan.description}
            features={plan.features}
            buttonText={plan.buttonText}
            buttonLink={plan.buttonLink}
            buttonColor={plan.buttonColor}
            buttonService={plan.buttonService}
          />
        ))}
      </div>
    </div>
  );
};
