import { Dispatch } from "redux";
import {
  CHECK_LOGIN_STATUS_REQUEST,
  CHECK_LOGIN_STATUS_SUCCESS,
  CHECK_LOGIN_STATUS_FAILURE,
} from "./../actionTypes/authActionTypes";
import  api from "../../services/api";
import { env } from "../../config/env";

export const checkLoginStatusRequest = () => ({
  type: CHECK_LOGIN_STATUS_REQUEST,
});

export const checkLoginStatusSuccess = (isLoggedIn: boolean) => ({
  type: CHECK_LOGIN_STATUS_SUCCESS,
  payload: isLoggedIn,  
});

export const checkLoginStatusFailure = (error: any) => ({
  type: CHECK_LOGIN_STATUS_FAILURE,
  payload: error,
});


export const checkLoginStatus = async(dispatch : Dispatch) => {
    dispatch(checkLoginStatusRequest());

    const baseUrl = env.apiUserManagementBaseUrl;

    try {
      const response = await api.get(`${baseUrl}/auth/accounts/is_logged_in`);

      if (response.data && response.data.status) {
        const loginStatus: string = response.data.status.toLowerCase();
        if (loginStatus === "user is logged in") {
          dispatch(checkLoginStatusSuccess(true));
        } else if (loginStatus === "user is not logged in") {
          dispatch(checkLoginStatusSuccess(false));
        } else {
          dispatch(
            checkLoginStatusFailure("Unexpected response: " + loginStatus)
          );
        }
      } else {
        dispatch(checkLoginStatusFailure("Invalid response format"));
      }
    } catch (error: any) {
      dispatch(checkLoginStatusFailure(error.message));
    }
  };
